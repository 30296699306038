.auth-card {
  display: flex;

  .auth-header-icon {
    margin: -2px 5px 0 0;
  }

  .auth-no-rep-text {
    h2 {
      margin-top: -5px;
    }

    h3 {
      margin-top: 2px;
    }

    .auth-no-rep-body {
      margin-top: 16px;
    }
  }

  .auth-rep-text {
    .auth-rep-header {
      h2 {
        margin-top: -7px;
        margin-bottom: 0px;
      }

      h3 {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      h4 {
        margin-top: 2px;
        margin-bottom: 0px;
      }
    }

    .auth-rep-subheader {
      h3,
      h4,
      h5 {
        margin-top: 12px;
        margin-bottom: 4px;
      }
    }
  }
}
